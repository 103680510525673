const state = {
  badges: [],
  badgeTypes: [],
  badge: '',
}
const getters = {
  badgesGetter: ({ badges }) => badges,
  badgeTypesGetter: ({ badgeTypes }) => badgeTypes,
  badgeGetter: ({ badge }) => badge,
}

const mutations = {
  setBadges: (state, payload) => (state.badges = payload),
  setBadge: (state, payload) => state.badges.push(payload),
  setBadgeTypes: (state, payload) => (state.badgeTypes = payload),
  setBadgeById: (state, payload) => (state.badge = payload),
  setUpdateBadge: (state, payload) => {
    state.badges = state.badges.map((badge) => {
      if (badge.id === payload.id) {
        return Object.assign({}, badge, payload)
      }
      return badge
    })
  },
  setDeleteBadge: (state, payload) => {
    state.badges = state.badges.filter((badge) => badge.id !== payload)
  },
}

const actions = {
  async createBadge({ commit }, payload) {
    await this.$axios.post('admin/badges', payload).then((res) => {
      commit('setBadge', res.data.result)
    })
  },
  async getBadges({ commit }, payload) {
    await this.$axios.get('admin/badges', { params: { ...payload } }).then((res) => {
      commit('setBadges', res.data.result)
    })
  },
  async getBadgeTypes({ commit }) {
    await this.$axios.get('admin/badge-types').then((res) => {
      commit('setBadgeTypes', res.data.result)
    })
  },
  async getBadge({ commit }, payload) {
    const params = payload && payload.params ? { ...payload.params } : {}
    await this.$axios.get(`admin/badges/${payload.id}`, { params: params }).then((res) => {
      commit('setBadge', res.data.result)
    })
  },
  async updateBadge({ commit }, payload) {
    await this.$axios.put(`admin/badges/${payload.id}`, payload).then((res) => {
      commit('setUpdateBadge', payload)
    })
  },
  deleteBadge({ commit }, payload) {
    this.$axios.delete(`admin/badges/${payload}`).then((res) => {
      commit('setDeleteBadge', payload)
    })
  },
}
export default { state, getters, mutations, actions }
