const state = {
  faqs: [],
  faqsMetaData: [],
}

const getters = {
  faqsGetter: ({ faqs }) => faqs,
  faqsMetaDataGetter: ({ faqsMetaData }) => faqsMetaData,
}

const mutations = {
  setFaqs: (state, payload) => (state.faqs = payload),
  setFaq: (state, payload) => state.faqs.push(payload),
  setFaqsMetaData: (state, payload) => (state.faqsMetaData = payload),
  setUpdateFaq: (state, payload) => {
    state.faqs = state.faqs.map((faq) => {
      if (faq.id === payload.id) {
        return Object.assign({}, faq, payload)
      }
      return faq
    })
  },
  setDeleteFaq: (state, payload) => {
    state.faqs = state.faqs.filter((faq) => faq.id !== payload)
  },
}

const actions = {
  async createFaq({ commit }, payload) {
    await this.$axios.post('admin/faqs', payload).then((res) => {
      commit('setFaq', res.data.result)
    })
  },
  async getFaqs({ commit }, payload) {
    await this.$axios.get('admin/faqs', { params: { ...payload } }).then((res) => {
      commit('setFaqs', res.data.result.items)
      commit('setFaqsMetaData', res.data.result.metadata)
    })
  },
  async updateFaq({ commit }, payload) {
    await this.$axios.put(`admin/faqs/${payload.id}`, payload).then((res) => {
      commit('setUpdateFaq', payload)
    })
  },
  deleteFaq({ commit }, payload) {
    this.$axios.delete(`admin/faqs/${payload}`).then((res) => {
      commit('setDeleteFaq', payload)
    })
  },
}
export default { state, getters, mutations, actions }
