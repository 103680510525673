const state = {
  places: [],
  place: '',
  placeMetaData: [],
}

const getters = {
  placesGetter: ({ places }) => places,
  placeGetter: ({ place }) => place,
  placeMetaDataGetter: ({ placeMetaData }) => placeMetaData,
}

const mutations = {
  setPlaces: (state, payload) => (state.places = payload),
  setBulkPlaces: (state, payload) => {
    payload.forEach((p) => {
      if (!state.places.find((place) => place.id === p.id)) state.places.push(p)
    })
  },
  setShowPlace: (state, payload) => (state.place = payload),
  setPlaceMetaData: (state, payload) => (state.placeMetaData = payload),
  setUpdatePlace: (state, payload) => {
    state.places = state.places.map((place) => {
      if (place.id === payload.id) {
        return Object.assign({}, place, payload)
      }
      return place
    })
  },
  setDeletePlace: (state, payload) => {
    state.places = state.places.filter((place) => place.id !== payload)
  },
}

const actions = {
  async createPlace({ commit }, payload) {
    await this.$axios.post('admin/places', payload).then((res) => {})
  },
  async getPlaces({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get('admin/places', { params: { ...filters } }).then((res) => {
      if (payload && payload.bulk) commit('setBulkPlaces', res.data.result.items)
      else commit('setPlaces', res.data.result.items)
      commit('setPlaceMetaData', res.data.result.metadata)
    })
  },
  async getPlace({ commit }, payload) {
    return await this.$axios.get(`admin/places/${payload.id}`).then((res) => {
      commit('setShowPlace', res.data.result)
      return res.data.result
    })
  },
  async updatePlace({ commit }, payload) {
    await this.$axios.put(`admin/places/${payload.id}`, payload).then((res) => {
      commit('setUpdatePlace', payload)
    })
  },
  deletePlace({ commit }, payload) {
    this.$axios.delete(`admin/places/${payload}`).then((res) => {
      commit('setDeletePlace', payload)
    })
  },
}
export default { state, getters, mutations, actions }
