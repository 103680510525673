const state = {
  programs: [],
  programMetaData: [],
}
const getters = {
  programsGetter: ({ programs }) => programs,
  programMetaDataGetter: ({ programMetaData }) => programMetaData,
}

const mutations = {
  setPrograms: (state, payload) => (state.programs = payload),
  setProgram: (state, payload) => state.programs.push(payload),
  setProgramMetaData: (state, payload) => (state.programMetaData = payload),
  setUpdateProgram: (state, payload) => {
    state.programs = state.programs.map((program) => {
      if (program.id === payload.id) {
        return Object.assign({}, program, payload)
      }
      return program
    })
  },
  setDeleteProgram: (state, payload) => {
    state.programs = state.programs.filter((program) => program.id !== payload)
  },
}

const actions = {
  async createProgram({ commit }, payload) {
    await this.$axios.post('admin/programs', payload).then((res) => {
      commit('setProgram', res.data.result)
    })
  },
  async getPrograms({ commit }, payload) {
    await this.$axios.get('admin/programs', { params: { ...payload } }).then((res) => {
      commit('setProgramMetaData', res.data.result.metadata)
      commit('setPrograms', res.data.result.items)
    })
  },
  async updateProgram({ commit }, payload) {
    await this.$axios.put(`admin/programs/${payload.id}`, payload).then((res) => {
      commit('setUpdateProgram', payload)
    })
  },
  deleteProgram({ commit }, payload) {
    this.$axios.delete(`admin/programs/${payload}`).then((res) => {
      commit('setDeleteProgram', payload)
    })
  },
}
export default { state, getters, mutations, actions }
