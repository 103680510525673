const state = {
  userRequests: [],
}
const getters = {
  userRequestsGetter: ({ userRequests }) => userRequests,
}

const mutations = {
  setUserRequests: (state, payload) => (state.userRequests = payload),
  setApproveRequest: (state, payload) => console.log(payload),
  setDeleteRequest: (state, payload) => {
    state.userRequests = state.userRequests.filter((userRequest) => userRequest.id !== payload)
  },
}

const actions = {
  async getUserRequests({ commit }) {
    await this.$axios.get('admin/ambassadors/requests').then((res) => {
      commit('setUserRequests', res.data.result.user_requests)
    })
  },
  approveUserRequest({ commit }, payload) {
    this.$axios.post(`admin/ambassadors/requests/${payload}/approve`, payload).then((res) => {
      commit('setApproveRequest', payload)
    })
  },
  deleteUserRequest({ commit }, payload) {
    this.$axios.delete(`admin/ambassadors/requests/${payload}`).then((res) => {
      commit('setDeleteRequest', payload)
    })
  },
}
export default { state, getters, mutations, actions }
