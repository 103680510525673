const state = {
  roles: [],
  roleMetaData: [],
}
const getters = {
  rolesGetter: ({ roles }) => roles,
  roleMetaDataGetter: ({ roleMetaData }) => roleMetaData,
}

const mutations = {
  setRoles: (state, payload) => (state.roles = payload),
  setBulkRoles: (state, payload) => state.roles.push(...payload),
  setRole: (state, payload) => state.roles.push(payload),
  setRoleMetaData: (state, payload) => (state.roleMetaData = payload),
  setUpdateRole: (state, payload) => {
    state.roles = state.roles.map((role) => {
      if (role.id === payload.id) {
        return Object.assign({}, role, payload)
      }
      return role
    })
  },
  setDeleteRole: (state, payload) => {
    state.roles = state.roles.filter((role) => role.id !== payload)
  },
}

const actions = {
  async createRole({ commit }, payload) {
    await this.$axios.post('admin/roles', payload).then((res) => {
      commit('setRole', res.data.result)
    })
  },
  async getRoles({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get('admin/roles', { params: { ...filters } }).then((res) => {
      commit('setRoleMetaData', res.data.result.metadata)
      if (payload && payload.bulk) commit('setBulkRoles', res.data.result.items)
      else commit('setRoles', res.data.result.items)
    })
  },
  async updateRole({ commit }, payload) {
    await this.$axios.put(`admin/roles/${payload.id}`, payload).then((res) => {
      commit('setUpdateRole', payload)
    })
  },
  deleteRole({ commit }, payload) {
    this.$axios.delete(`admin/roles/${payload}`).then((res) => {
      commit('setDeleteRole', payload)
    })
  },
}
export default { state, getters, mutations, actions }
