const state = {
  translations: [],
  translationMetaData: [],
  language: [],
}
const getters = {
  translationsGetter: ({ translations }) => translations,
  translationMetaDataGetter: ({ translationMetaData }) => translationMetaData,
  languageGetter: ({ language }) => language,
}

const mutations = {
  setTranslations: (state, payload) => (state.translations = payload),
  setLanguage: (state, payload) => (state.language = payload),
  setTranslationMetaData: (state, payload) => (state.translationMetaData = payload),
}

const actions = {
  async getTranslations({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get(`admin/translations`, { params: { ...filters } }).then((res) => {
      commit('setTranslationMetaData', res.data.result.metadata)
      commit('setTranslations', res.data.result.items)
    })
  },
  async updateTranslation({ commit }, payload) {
    await this.$axios.put(`admin/translations`, payload)
  },
  async createTranslation({ commit }, payload) {
    await this.$axios.post(`admin/translations`, payload)
  },
  async getLanguage({ commit }, payload) {
    await this.$axios.get(`admin/languages`, payload).then((res) => {
      commit('setLanguage', res.data.result.items)
    })
  },
}
export default { state, getters, mutations, actions }
