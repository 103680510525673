const state = {
  tenants: [],
  tenantMetaData: [],
}
const getters = {
  tenantsGetter: ({ tenants }) => tenants,
  tenantMetaDataGetter: ({ tenantMetaData }) => tenantMetaData,
}

const mutations = {
  setTenants: (state, payload) => (state.tenants = payload),
  setTenantMetaData: (state, payload) => (state.tenantMetaData = payload),
  setUpdateTenant: (state, payload) => {
    state.tenants = state.tenants.map((tenant) => {
      if (tenant.id === payload.id) {
        return Object.assign({}, tenant, payload)
      }
      return tenant
    })
  },
}

const actions = {
  async getTenants({ commit }) {
    await this.$axios.get('manager/tenants').then((res) => {
      commit('setTenantMetaData', res.data.result.metadata)
      commit('setTenants', res.data.result.items)
    })
  },
  async updateTenant({ commit }, payload) {
    await this.$axios.put(`manager/tenants/${payload.id}`, payload).then((res) => {
      commit('setUpdateTenant', payload)
    })
  },
}
export default { state, getters, mutations, actions }
