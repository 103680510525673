<template>
  <div class="layout-menu-container">
    <ManagerSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
  </div>
</template>

<script>
import ManagerSubmenu from './ManagerSubmenu.vue'

export default {
  components: {
    ManagerSubmenu: ManagerSubmenu,
  },
  props: {
    model: Array,
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme
    },
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event)
    },
    onKeyDown(event) {
      const nodeElement = event.target
      if (event.code === 'Enter' || event.code === 'Space') {
        nodeElement.click()
        event.preventDefault()
      }
    },
    bannerImage() {
      return this.$appState.darkTheme ? 'images/banner-primeblocks-dark.png' : 'images/banner-primeblocks.png'
    },
  },
}
</script>