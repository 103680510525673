const state = {
  tasks: [],
  userTasks: [],
  userTaskMetaData: [],
  taskById: null,
  taskMetaData: [],
  addTask: null,
}
const getters = {
  tasksGetter: ({ tasks }) => tasks,
  userTasksGetter: ({ userTasks }) => userTasks,
  taskByIdGetter: ({ taskById }) => taskById,
  addTaskGetter: ({ addTask }) => addTask,
  taskMetaDataGetter: ({ taskMetaData }) => taskMetaData,
  userTaskMetaDataGetter: ({ userTaskMetaData }) => userTaskMetaData,
}

const mutations = {
  setTasks: (state, payload) => (state.tasks = payload),
  setUserTasks: (state, payload) => (state.userTasks = payload),
  setTaskById: (state, payload) => (state.taskById = payload),
  setTask: (state, payload) => {
    if (payload.type === 'task') state.tasks.push(payload.res)
    state.addTask = { ...payload.res }
  },
  setTaskMetaData: (state, payload) => (state.taskMetaData = payload),
  setUserTaskMetaData: (state, payload) => (state.userTaskMetaData = payload),
  setUpdateTask: (state, payload) => {
    if (payload.parent_id) {
      state.taskById.children = state.taskById.children.map((task) => {
        if (task.id === payload.id) {
          return Object.assign({}, task, payload)
        }
        return task
      })
    } else
      state.tasks = state.tasks.map((task) => {
        if (task.id === payload.id) {
          return Object.assign({}, task, payload)
        }
        return task
      })
  },
  setDeleteTask: (state, payload) => {
    state.tasks = state.tasks.filter((task) => task.id !== payload)
  },
  setDeleteUserTask: (state, payload) => {
    state.userTasks = state.userTasks.filter((task) => task.task.id !== payload)
  },
}

const actions = {
  async createTask({ commit }, payload) {
    await this.$axios.post('admin/tasks', payload['_task']).then((res) => {
      const data = res.data.result
      commit('setTask', { res: { ...payload._task, id: data.id, status: data.status }, type: payload.type })
    })
  },
  getTasks({ commit }, payload) {
    this.$axios.get('admin/tasks', { params: { ...payload } }).then((res) => {
      commit('setTaskMetaData', res.data.result.metadata)
      commit('setTasks', res.data.result.items)
    })
  },
  getUserTasks({ commit }, payload) {
    this.$axios.get('admin/user-tasks', { params: { ...payload } }).then((res) => {
      commit('setUserTaskMetaData', res.data.result.metadata)
      commit('setUserTasks', res.data.result.items)
    })
  },
  getTaskById({ commit }, payload) {
    this.$axios.get(`admin/tasks/${payload.id}`, { params: { ...payload.params } }).then((res) => {
      commit('setTaskById', res.data.result)
    })
  },
  async updateTask({ commit }, payload) {
    await this.$axios.put(`admin/tasks/${payload.id}`, payload).then((res) => {
      commit('setUpdateTask', payload)
    })
  },
  async deleteTask({ commit }, payload) {
    await this.$axios.delete(`admin/tasks/${payload.id}`, { params: { ...payload.params } }).then((res) => {
      commit('setDeleteTask', payload.id)
    })
  },
  async approveUserTask({ commit }, payload) {
    await this.$axios.post(`admin/tasks/${payload.taskId}/approve/${payload.userId}`).then((res) => {
      commit('setDeleteUserTask', payload.taskId)
    })
  },
  async disapproveUserTask({ commit }, payload) {
    await this.$axios.post(`admin/tasks/${payload.taskId}/decline/${payload.userId}`).then((res) => {
      commit('setDeleteUserTask', payload.taskId)
    })
  },
}
export default { state, getters, mutations, actions }
