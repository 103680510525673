import { createStore } from 'vuex'
import auth from './modules/auth'
import teams from './modules/teams'
import ambassadors from './modules/ambassadors'
import admins from './modules/admins'
import roles from './modules/roles'
import permissions from './modules/permissions'
import tasks from '@/store/modules/tasks'
import userRequests from '@/store/modules/userRequests'
import places from '@/store/modules/places'
import profile from '@/store/modules/profile'
import media from '@/store/modules/media'
import badges from '@/store/modules/badges'
import groups from '@/store/modules/groups'
import invites from '@/store/modules/invites'
import withdrawalRequests from '@/store/modules/withdrawalRequests'
import wallets from '@/store/modules/wallets'
import questionCategories from '@/store/modules/questionCategories'
import faqs from '@/store/modules/faqs'
import campaigns from '@/store/modules/campaigns'
import notificationTemplates from '@/store/modules/notification-templates'
import dashboard from '@/store/modules/dashboard'
import programs from '@/store/modules/programs'
import agencies from '@/store/modules/agencies'
import currencies from '@/store/modules/currencies'
import tenants from '@/store/modules/tenants'
import translation from '@/store/modules/translation'
import managerProfile from '@/store/modules/manager-profile'

// Create a new store instance.
export const store = createStore({
  state: {
    error: null,
    message: null,
    filesUrl: '',
    imagesUrl: '',
    mediaUrl: '',
    tenant: null,
  },
  getters: {
    messageGetter: ({ message }) => message,
  },
  mutations: {
    setMessage: (state, payload) => {
      state.message = payload
    },
    setInit(state, res) {
      state.filesUrl = res.data.result.files_url
      state.imagesUrl = res.data.result.images_url
      state.mediaUrl = res.data.result.media_url
      state.tenant = res.data.result.tenant
    },
  },
  actions: {
    async getInit({ state, commit }) {
      await this.$axios.get(`${this.$main_url}/init`).then((res) => {
        commit('setInit', res)
      })
    },
  },
  modules: {
    auth,
    teams,
    ambassadors,
    admins,
    roles,
    permissions,
    userRequests,
    tasks,
    places,
    profile,
    media,
    badges,
    groups,
    invites,
    withdrawalRequests,
    wallets,
    faqs,
    questionCategories,
    campaigns,
    notificationTemplates,
    dashboard,
    programs,
    agencies,
    currencies,
    tenants,
    translation,
    managerProfile,
  },
})
