const state = {
  notificationTemplates: [],
  notificationTemplateMetaData: [],
}
const getters = {
  notificationTemplatesGetter: ({ notificationTemplates }) => notificationTemplates,
  notificationTemplateMetaDataGetter: ({ notificationTemplateMetaData }) => notificationTemplateMetaData,
}

const mutations = {
  setNotificationTemplates: (state, payload) => (state.notificationTemplates = payload),
  setBulkNotificationTemplates: (state, payload) => state.notificationTemplates.push(...payload),
  setNotificationTemplate: (state, payload) => state.notificationTemplates.push(payload),
  setNotificationTemplateMetaData: (state, payload) => (state.notificationTemplateMetaData = payload),
  setUpdateNotificationTemplate: (state, payload) => {
    state.notificationTemplates = state.notificationTemplates.map((notificationTemplate) => {
      if (notificationTemplate.id === payload.id) {
        return Object.assign({}, notificationTemplate, payload)
      }
      return notificationTemplate
    })
  },
  setDeleteNotificationTemplate: (state, payload) => {
    state.notificationTemplates = state.notificationTemplates.filter((notificationTemplate) => notificationTemplate.id !== payload)
  },
}

const actions = {
  async createNotificationTemplate({ commit }, payload) {
    await this.$axios.post('admin/notifications/notification-templates', payload).then((res) => {
      commit('setNotificationTemplate', res.data.result)
    })
  },
  async getNotificationTemplates({ commit }, payload) {
    const filters = payload && payload.filters ? { ...payload.filters } : {}
    await this.$axios.get('admin/notifications/notification-templates', { params: { ...filters } }).then((res) => {
      commit('setNotificationTemplateMetaData', res.data.result.metadata)
      if (payload && payload.bulk) commit('setBulkNotificationTemplates', res.data.result.items)
      else commit('setNotificationTemplates', res.data.result.items)
    })
  },
  async updateNotificationTemplate({ commit }, payload) {
    await this.$axios.put(`admin/notifications/notification-templates/${payload.id}`, payload).then((res) => {
      commit('setUpdateNotificationTemplate', payload)
    })
  },
  updateNotificationTemplateRoles({ commit }, payload) {
    console.log(payload.params)
    this.$axios.put(`admin/notifications/notification-templates/${payload.id}/roles`, {}, { params: payload.params }).then((res) => {})
  },
  deleteNotificationTemplate({ commit }, payload) {
    this.$axios.delete(`admin/notifications/notification-templates/${payload}`).then((res) => {
      commit('setDeleteNotificationTemplate', payload)
    })
  },
}
export default { state, getters, mutations, actions }
