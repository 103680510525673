<template>
  <div class="layout-topbar">
    <router-link to="/manager" class="layout-topbar-logo">
      <img :src="logo" alt="Ea Team logo" style="width: auto; object-fit: cover" />
    </router-link>
    <button class="p-link layout-menu-button layout-topbar-button" @click="onMenuToggle">
      <i class="pi pi-bars"></i>
    </button>

    <button
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
      class="p-link layout-topbar-menu-button layout-topbar-button"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
        <OverlayPanel ref="profile" append-to="body">
          <div class="flex flex-column gap-2 justify-content-center align-items-center">
            <router-link to="/manager/profile"><Button label="Profile" class="p-button-text" /></router-link>
            <Button label="Log out" class="p-button-danger" @click="handleLogoutButton" />
          </div>
        </OverlayPanel>
        <button class="p-link layout-topbar-button" @click="toggle">
          <i class="pi pi-user"></i>
          <span>Profile</span>
        </button>
      </li>
    </ul>
  </div>
</template>


<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import router from '@/router/router'

export default {
  emits: ['menu-toggle', 'topbar-menu-toggle'],
  setup(props, { emit }) {
    const store = useStore()
    const profile = ref(null)

    const darkTheme = computed(() => store.$appState.darkTheme)
    const logo = computed(() => store.state.imagesUrl + '/' + store.state.tenant.logo)

    return {
      logo,
      darkTheme,
      profile,
      toggle(event) {
        profile.value.toggle(event)
      },
      onMenuToggle(event) {
        emit('menu-toggle', event)
      },
      onTopbarMenuToggle(event) {
        emit('topbar-menu-toggle', event)
      },
      topbarImage() {
        return darkTheme.value ? 'layout/images/logo-white.svg' : logo
      },
      handleLogoutButton() {
        store.dispatch('managerlogout')
      },
    }
  },
}
</script>

<style>
.p-datatable-emptymessage td {
  text-align: center !important;
}
</style>