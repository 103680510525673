const AuthRoutes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/Login.vue'),
    meta: { auth: false },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('@/pages/ForgotPassword.vue'),
    meta: { auth: false },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/pages/ResetPassword.vue'),
    meta: { auth: false },
  },
  {
    path: '/manager/login',
    name: 'manager-login',
    component: () => import('@/pages/manager/Login.vue'),
    meta: { auth: false },
  },
]

export default AuthRoutes
