const state = {
  uploadImage: '',
}

const getters = {
  uploadImageGetter: ({ uploadImage }) => uploadImage,
}

const mutations = {
  setUploadImage: (state, payload) => (state.uploadImage = payload),
}

const actions = {
  async uploadFile({ commit }, payload) {
    const formData = new FormData()
    await Object.keys(payload.files).forEach((x) => {
      formData.append('files[]', payload.files[x])
    })
    await Object.keys(payload.info).forEach((x) => {
      console.log(x)
      formData.append(x, payload.info[x])
    })
    await this.$axios
      .post(`${this.$main_url}/api/resource-upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('setUploadImage', { file_relative_url: res.data.result[0].file_relative_url, pid: res.data.result[1].pid })
      })
  },
  async importFile({ commit }, payload) {
    const formData = new FormData()
    formData.append('excel', payload.file[0])
    await this.$axios.post(`${this.$main_url}/api/admin/ambassadors/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  async managerUploadFile({ commit }, payload) {
    const formData = new FormData()
    await Object.keys(payload.files).forEach((x) => {
      formData.append('files[]', payload.files[x])
    })
    await Object.keys(payload.info).forEach((x) => {
      console.log(x)
      formData.append(x, payload.info[x])
    })
    console.log('formData', formData)
    await this.$axios
      .post(`${this.$main_url}/api/manager/resource-upload/${payload.info.pid}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        commit('setUploadImage', { file_relative_url: res.data.result[0].file_relative_url, pid: res.data.result[1].pid })
      })
  },
}
export default { state, getters, mutations, actions }
