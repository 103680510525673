import { createRouter, createWebHistory } from 'vue-router'
import App from '@/App.vue'
import Routes from './routers'
import { store } from '@/store/store'
import Cookies from 'js-cookie'
import Manager from '@/Manager.vue'

const routes = [
  ...Routes,
  {
    path: '/',
    name: 'app',
    component: App,
    meta: { auth: true },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/components/prime/Dashboard.vue'),
      },
      {
        path: '/teams',
        name: 'teams',
        component: () => import('@/pages/Teams.vue'),
      },
      {
        path: '/residences',
        name: 'programmes',
        component: () => import('@/pages/Programs.vue'),
      },
      {
        path: '/ambassadors',
        name: 'ambassadors',
        component: () => import('@/pages/ambassadors'),
      },
      {
        path: '/admins',
        name: 'admins',
        component: () => import('@/pages/admins/index.vue'),
      },
      {
        path: '/roles',
        name: 'roles',
        component: () => import('@/pages/Roles.vue'),
      },
      {
        path: '/tasks',
        name: 'tasks',
        component: () => import('../pages/tasks'),
      },
      {
        path: '/badges',
        name: 'badges',
        component: () => import('../pages/badges'),
      },
      {
        path: '/question-categories',
        name: 'question-categories',
        component: () => import('../pages/QuestionCategories'),
      },
      {
        path: '/wallets',
        name: 'wallets',
        component: () => import('../pages/wallets'),
      },
      {
        path: '/invites',
        name: 'invites',
        component: () => import('../pages/Invites'),
      },
      {
        path: '/faqs',
        name: 'faqs',
        component: () => import('../pages/Faqs'),
      },
      {
        path: '/campaigns',
        name: 'campaigns',
        component: () => import('../pages/Campaigns'),
      },
      {
        path: '/notification-templates',
        name: 'notification-templates',
        component: () => import('../pages/NotificationTemplates'),
      },
      {
        path: '/places',
        name: 'places',
        component: () => import('../pages/Places'),
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import('../pages/profile'),
      },
      {
        path: '/translations',
        name: 'translations',
        component: () => import('../pages/Translation.vue'),
      },
    ],
  },
  {
    path: '/landing',
    name: 'landing',
    component: () => import('@/pages/LandingDemo.vue'),
  },
  {
    path: '/:path(.*)',
    name: 'notfound',
    component: () => import('@/pages/NotFound.vue'),
  },
  {
    path: '/access',
    name: 'access',
    component: () => import('@/pages/Access.vue'),
  },

  {
    path: '/manager',
    name: 'managerApp',
    component: Manager,
    meta: { auth: true },
    children: [
      {
        path: '/manager/tenants',
        name: 'tenants',
        component: () => import('@/pages/manager/Tenants.vue'),
      },
      {
        path: '/manager/profile',
        name: 'manager/profile',
        component: () => import('@/pages/manager/ProfileIndex.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  const token = await Cookies.get('token')
  if (token) await store.commit('setToken', token)
  if ('auth' in to.meta && to.meta.auth && !token) {
    next('/login')
  } else if ('auth' in to.meta && !to.meta.auth && token) {
    next('/')
  } else {
    next()
  }
})
export default router
